import React from 'react'
import { css } from '@emotion/core'
import { Text } from '@/app/atoms/text/text'
import { ContactForm } from '@/app/organisms/contactForm/contactForm'
import { SEO } from '@/app/organisms/layout/components/seo'

type ContactProps = {}

const Contact: React.FC<ContactProps> = () => {
  return (
    <>
      <SEO pageTitle="Contact Us" />
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 60px;
          padding: 0px 8px;
        `}
      >
        <Text type="title">Contact Us</Text>
        Leave us a message and we'll get back with you
        <ContactForm />
      </div>
    </>
  )
}

export default Contact
