import React, { useState, FC, SyntheticEvent } from 'react'
import { css } from '@emotion/core'
import { Input } from '../../molecules/input/input'
import { ThemeTypes } from '@/app/theme/theme'
import { useThemeHook } from '../../../hooks/useThemeHook'
import { Button } from '@/app/atoms/button/button'
import { navigate } from 'gatsby-link'
import { TextArea } from '@/app/molecules/textArea/textArea'

type ContactFormProps = {
  title?: string
}
type Status = {
  type: 'success' | 'error'
  content: string
}
type StatusType = {
  name: Status
  message: Status
  email: Status
  phone: Status
  zipCode: Status
}

export const ContactForm: FC<ContactFormProps> = ({ title }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState<StatusType>({
    name: {
      type: 'success',
      content: '',
    },
    phone: {
      type: 'success',
      content: '',
    },
    email: {
      type: 'success',
      content: '',
    },
    message: {
      type: 'success',
      content: '',
    },
    zipCode: {
      type: 'success',
      content: '',
    },
  })

  const theme = useThemeHook()

  function encode(data: any) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const formatPhone = (value: string) => {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneLength = value.length
    if (value.length < 4) {
      return phoneNumber
    }
    if (phoneLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6,
    )}-${phoneNumber.slice(6, 10)}`
  }

  const formatZip = (value: string) => {
    if (!value) return value
    const zipCode = value.replace(/[^\d]/g, '')

    return zipCode.slice(0, 5)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const error = 'error'
    let newStatus: StatusType = {
      name: {
        type: 'success',
        content: '',
      },
      phone: {
        type: 'success',
        content: '',
      },
      email: {
        type: 'success',
        content: '',
      },
      message: {
        type: 'success',
        content: '',
      },
      zipCode: {
        type: 'success',
        content: '',
      },
    }

    if (email.length < 1) {
      newStatus = {
        ...newStatus,
        email: {
          type: error,
          content: 'Please enter your email',
        },
      }
    }
    if (phone.length < 1) {
      newStatus = {
        ...newStatus,
        phone: {
          type: error,
          content: 'Please enter your phone number',
        },
      }
    }
    if (message.length < 1) {
      newStatus = {
        ...newStatus,
        message: {
          type: error,
          content: 'Please leave us a detailed message',
        },
      }
    }
    if (name.length < 1) {
      newStatus = {
        ...newStatus,
        name: {
          type: error,
          content: 'Please enter your name',
        },
      }
      if (zipCode.length < 1) {
        newStatus = {
          ...newStatus,
          zipCode: {
            type: error,
            content: 'Please enter your zip code',
          },
        }
      }
    }
    setStatus(newStatus)

    const form = e.target
    console.log({
      name,
      message,
      phone,
      email,
      zipCode,
    })
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        name,
        message,
        phone,
        email,
        zipCode,
      }),
    })
      .then(() => setIsLoading(true))
      .then(() => {
        if (
          name.length < 1 ||
          email.length < 1 ||
          phone.length < 1 ||
          message.length < 1 ||
          zipCode.length < 1
        ) {
          throw new Error()
        }
      })
      .then(() => navigate('/thank-you/'))
      .catch(error => error)
      .finally(() => setIsLoading(false))
  }
  return (
    <div
      css={(theme: ThemeTypes) => css`
        background: ${theme.background};
        width: 100%;
        min-width: 320px;
        max-width: 724px;
        border-radius: 6px;
        padding: 32px 16px;
      `}
    >
      {title && (
        <h2
          css={css`
            padding-left: 8px;
            padding-bottom: 16px;
          `}
        >
          {title}
        </h2>
      )}
      <form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="contact" value="contact" />
        <Input
          message={status.name}
          name="name"
          labelPlaceholder={'Name'}
          type="text"
          onChange={name => setName(name)}
          value={name}
        />
        <div
          css={css`
            margin: 32px 0px;
          `}
        >
          <Input
            message={status.email}
            name="email"
            labelPlaceholder={'Email'}
            type="email"
            onChange={email => setEmail(email)}
            value={email}
          />
        </div>
        <div
          css={css`
            margin: 32px 0px;
          `}
        >
          <Input
            message={status.phone}
            name="phone"
            labelPlaceholder={'Phone (XXX)-XXX-XXXX'}
            type="text"
            onChange={phone => setPhone(formatPhone(phone))}
            value={phone}
          />
        </div>
        <div
          css={css`
            margin: 32px 0px;
          `}
        >
          <Input
            message={status.zipCode}
            name="zipCode"
            labelPlaceholder={'Zip Code'}
            type="text"
            onChange={zip => setZipCode(formatZip(zip))}
            value={zipCode}
          />
        </div>
        <div
          css={css`
            margin: 32px 0px;
          `}
        >
          <TextArea
            message={status.message}
            name="message"
            labelPlaceholder={'Message'}
            onChange={message => setMessage(message)}
            value={message}
          />
        </div>
        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: centser;
            margin: 32px 0px;
          `}
        >
          <Button
            style={'primary'}
            size="large"
            type="submit"
            isDisabled={isLoading ? true : false}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}
