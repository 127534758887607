import React, { FC } from 'react'
import styles from './styles'
import { useThemeHook } from '../../../hooks/useThemeHook'
import { AnimateSharedLayout, motion } from 'framer-motion'

type TextAreaProps = {
  label?: string
  placeHolder?: string
  labelPlaceholder?: string
  name?: string
  message?: {
    type: 'success' | 'error'
    content: string
  }
  onChange: (value: string) => void
  value: string
}
export const TextArea: FC<TextAreaProps> = ({
  onChange,
  value,
  placeHolder,
  name,
  message,
  label,
  labelPlaceholder,
}) => {
  const isActive = value ? true : false
  const isLabelPlaceholder = labelPlaceholder ? true : false
  const theme = useThemeHook()
  const handleSetValue = (e: any) => {
    onChange(e.target.value)
  }
  return (
    <AnimateSharedLayout>
      <div className={styles.flexContainer}>
        <div
          className={styles.inputContainer(isActive, theme, isLabelPlaceholder)}
        >
          <main className={styles.inputWrapper}>
            <textarea
              name={name}
              value={value}
              placeholder={placeHolder || labelPlaceholder}
              onChange={handleSetValue}
            />
            <label htmlFor={name}>{label || labelPlaceholder}</label>
          </main>
          <div className={styles.underglow}></div>
        </div>
        {message?.content && (
          <motion.div layoutId="message" className={styles.messageContainer}>
            <span className={styles.message(theme, message.type)}>
              {message.content}
            </span>
          </motion.div>
        )}
      </div>
    </AnimateSharedLayout>
  )
}
