import { css } from 'emotion'
import { theme, ThemeTypes } from '@/app/theme/theme'

export default {
  flexContainer: css`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
  `,
  inputContainer: (
    active: boolean,
    theme: ThemeTypes,
    isLabelPlaceholder: boolean,
  ) => css`
    font-family: inherit;
    display: flex;
    align-items: center;
    height: 120px;
    border-radius: 6px;
    width: 100%;
    background: ${theme.inputs.backgroundColor};
    padding: 8px 8px;
    label {
      position: absolute;
      top: ${isLabelPlaceholder ? (active ? '-32px' : '10px') : '-32px'};
      right: ${isLabelPlaceholder ? (active ? 0 : '-8px') : 0};
      color: ${isLabelPlaceholder
        ? active
          ? theme.inputs.activeColor
          : 'transparent'
        : theme.inputs.activeColor};
      width: 100%;
      z-index: -1;
      transition: ${isLabelPlaceholder && 'all 0.3s ease-out'};
    }
    textarea {
      resize: none;
      font-family: inherit;
      font-size: 16px;
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 120px;
      border-radius: '10px';
      display: inline-block;
      border: 0;
      outline: none;
      padding: 0px 8px;
      color: ${theme.inputs.fontColor}; // Add color
    }
    textarea::placeholder {
      color: ${theme.inputs.fontColor}; // Add color
      transition: color 0.3s ease-in;
      font-family: inherit;
    }
    &:focus-within {
      > div {
        border: 2px solid ${theme.inputs.activeColor}; // Add color
        transition: border 0.3s;
      }
      textarea::placeholder {
        color: transparent;
        transition: color 0.3s;
      }
      label {
        top: -32px;
        right: 0;
        z-index: 3;
        color: ${theme.inputs.activeColor}; // TODO theme this color
        transition: all 0.3s;
      }
    }
  `,
  underglow: css`
    width: calc(100% - 2px);
    height: 120px;
    position: absolute;
    top: 2px;
    left: 1px;
    z-index: -1;
    border-radius: inherit;
    padding: 4px;
    border: 2px solid transparent;
  `,
  inputWrapper: css`
    width: 100%;
    height: 100%;
    position: relative;
  `,
  messageContainer: css`
    width: 100%;
    margin-top: 8px;
  `,
  message: (theme: ThemeTypes, color: 'success' | 'error') => css`
    margin: 4px 0 0 8px;
    font-size: 1em;
    color: ${theme.inputs.status[color]}; // Add color
  `,
}
